import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import logo from '../../../assets/img/big-logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, NavLink } from 'react-router-dom';
import api from '../../../api';
import { ReactComponent as IconStars } from '../../../assets/img/icons/icon-stars.svg';
import { GenerateFormContext } from '../GeneratorContextComponent';
import { getFormOptions, getSocket, getToken, setUser } from '../../../redux/slices/applicationSlice';
import { getUserUpdateThunk } from '../../../redux/thunks/applicationThunks';
import { setGenerateLoading, resetBlogGenerator, setBlogs, setBlogDone, } from '../../../redux/slices/blogGeneratorSlice';
import { checkUserHaveCredits } from '../../../helpers/userHelpers';

import { ReactComponent as PlusIcon } from '../../../assets/img/icons/icons-plus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/img/icons/icon-check-circle.svg';
import { ReactComponent as CloseIcon } from '../../../assets/img/icons/icon-close.svg';

import SelectBox from "../../../components/base/SelectBox";
import NumberInput from "../../../components/base/NumberInput";
import KeywordsGenerator from "./KeywordsGenerator";
import PrimaryButton from '../../../components/base/PrimaryButton';

import { toast } from 'react-toastify';
import { v4 } from 'uuid';

const MetaTagConfigComponent = ({ setLoading, currentCredits, priceCredits, changePayloadForCalculate, }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const formOptions = useSelector(getFormOptions);
    const languageOptions = formOptions.filter(({key}) => key === 'language');
    const brandToneOptions = formOptions.filter(({key}) => key === 'bot_brand_tone').map(item => ({ value: item.title, label: item.title }));

    const socket = useSelector(getSocket);
    const token = useSelector(getToken);
    const bitId = searchParams.get('bitId');

    const [pageInputValue, setPageInputValue] = useState('');

    const [generateForm, setGenerateForm] = useContext(GenerateFormContext);
    const { elements, keywords, language, description, variants, bot_brand_tone } = generateForm;
    console.log(generateForm, 'generateForm')
    const [inputValue, setInputValue] = useState('');
    
    const tagsList = [
        "Title",
        "Meta Description",
        "Meta Keywords",
        "Viewport",
        "Robots Meta",
    ]

    const submitDisabled = !elements?.length || !description?.length;

    const handleSubmit = async (e) => {
        try {
            let checkRes = await checkUserHaveCredits(token);
            if(!checkRes?.success){
                dispatch(setUser(checkRes?.data));
                return;
            }

            setLoading(true);
            dispatch(resetBlogGenerator());
            dispatch(setGenerateLoading(true));

            const eventId = v4();

            // {
            //     elements: ['Meta Keywords', 'Page Title', 'Page Description', 'Poem of page'],
            //     keywords: ['Wild Life', 'Recipes'],
            //     language: 'English',
            //     description: 'Explore a world of innovation and creativity at our online platform. Immerse yourself in a diverse range of articles, tutorials, and resources covering technology, art, science, and more. Join our community and stay updated on the latest trends, tips, and breakthroughs. Your journey to inspiration starts here.',
            //     variants: 4,
            // }
            
            let settingsPayload = {
                elements,
                keywords,
                language,
                description,
                variants,
            };

            const response = await api.assets.createAsset({
                data: {
                    name: `Meta tags: ${elements.join(', ')}`,
                    content: JSON.stringify([
                        {
                            settings: settingsPayload,
                            versions: [],
                            activeVersionIndex: 0,
                            generatedDate: new Date().toUTCString(),
                            selectedVersionId: null,
                        }
                    ]),
                    productId: 13,
                    spentCredits: priceCredits
                },
                token
            });

            if(!response.success) return;

            const toastId = toast.loading(<div className='flex flex-col items-center gap-[10px]'>
                <p>Generating your meta tags, please wait...</p>
                <div className="d-flex items-center gap-[10px]">
                    <PrimaryButton 
                        className="min-w-[100px]"
                        onClick={() => { 
                            navigate(`/generated-page/meta-tags/${response.data.id}`)
                        }}
                    >View</PrimaryButton>
                    <PrimaryButton onClick={() => {
                        socket.send({
                            event: 'stop-event-generation',
                            payload: {
                                id: eventId
                            },
                            handler: (response) => {
                                console.log(response);
                                toast.dismiss(toastId)
                            }
                        })  
                    }}>Stop Generation</PrimaryButton>
                </div>
            </div>);

            socket.send({
                id: eventId,
                event: 'meta-tag-create',
                payload: settingsPayload,
                handler: (data) => {
                    if(data?.isError){
                        toast.error("AI bot is not available at the moment");
                        return;
                    }

                    let payloadToStore = [
                        {
                            settings: settingsPayload,
                            versions: data.payload.map((item, itemIndex) => ({
                                title: `Meta tags version ${itemIndex+1}`,
                                content: item.data,
                                activeContent: item.data,
                                isDone: item.isDone,
                                activeButton: "",
                                id: `${response.data.id}-meta-tags-${itemIndex}`,
                            })),
                            activeVersionIndex: 0,
                            generatedDate: new Date().toUTCString(),
                            selectedVersionId: null,
                        }
                    ];

                    if(data?.isDone) {
                        dispatch(getUserUpdateThunk({ token }));

                        api.assets.changeAsset({
                            id: response.data.id,
                            data: {
                                content: JSON.stringify(payloadToStore)
                            },
                            token
                        }).then(resp => {console.log(resp)});

                        toast.update(toastId, {
                            render: 'Generated successfully!',
                            type: 'success', 
                            isLoading: false,
                            autoClose: 5000
                        });

                        dispatch(setGenerateLoading(false));
                        dispatch(setBlogDone(true));
                    }

                    if(window.location.pathname === `/generated-page/meta-tags/${response.data.id}`) {
                        dispatch(setBlogs(payloadToStore));
                    };
                }
            });

            navigate(`/generated-page/meta-tags/${response.data.id}`);
        } catch(e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const test = false;
        if(bitId) return; 
        setGenerateForm(test ? {
            elements: ['Meta Keywords', 'Page Title', 'Page Description', 'Poem of page'],
            keywords: ['Wild Life', 'Recipes'],
            language: 'English',
            description: 'Explore a world of innovation and creativity at our online platform. Immerse yourself in a diverse range of articles, tutorials, and resources covering technology, art, science, and more. Join our community and stay updated on the latest trends, tips, and breakthroughs. Your journey to inspiration starts here.',
            variants: 3
        } : {
            elements: [],
            keywords: [],
            language: 'English',
            description: '',
            variants: 3
        });
    }, [])

    useEffect(() => {
        if(!bitId || !token) return;
        api.assets.getAssetById({
            id: bitId,
            token
        }).then(resp => {
            if(!resp.success || resp.data.productId !== 10) return;
            const data = JSON.parse(resp.data.content);
            setGenerateForm(prev => ({
                ...prev,
                ...data,
            }))
        }).catch(e => console.log(e))
    }, [bitId, token])

    useEffect(() => {
        let settingsPayload = {
            elements,
            keywords,
            language,
            description,
            variants,
            bot_brand_tone
        };

        if(!submitDisabled) {
            changePayloadForCalculate({
                event: 'meta-tag-create',
                data: settingsPayload
            })
        }
    }, [generateForm])

    return <>
        <div className='gen4-content'>
            <div className='w-full max-w-[500px] m-auto mt-0'>
                <div className="get4-content-header mb-[36px]">
                    <div className='flex items-center justify-center gap-[12px]'>
                        <div className='blog-kit-icon-container'>
                            <svg width="24" height="24" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="jam:write-f" filter="url(#filter0_d_2309_87412)">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21.4829 7.483L18.1836 4.18367C17.7461 3.74617 17.1523 3.5 16.5339 3.5H8.16659C6.87742 3.5 5.83325 4.54417 5.83325 5.83333V22.1667C5.83325 23.4558 6.87742 24.5 8.16659 24.5H19.8333C21.1224 24.5 22.1666 23.4558 22.1666 22.1667V9.13267C22.1666 8.51433 21.9204 7.9205 21.4829 7.483V7.483Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M22.1666 9.33333H17.4999C16.8559 9.33333 16.3333 8.81067 16.3333 8.16667V3.5" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.33325 12.8333H16.3333" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.33325 16.3333H16.3333" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.33325 19.8333H14.3849" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </g>
                                <defs>
                                    <filter id="filter0_d_2309_87412" x="-0.205653" y="0.117877" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix>
                                        <feOffset dy="0.823529"></feOffset><feGaussianBlur stdDeviation="0.823529"></feGaussianBlur>
                                        <feComposite in2="hardAlpha" operator="out"></feComposite>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2309_87412"></feBlend>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2309_87412" result="shape"></feBlend>
                                    </filter>
                                    <linearGradient id="paint0_linear_2309_87412" x1="10.6199" y1="2.70568" x2="10.6199" y2="18.2806" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="white"></stop><stop offset="1" stopColor="#E0E0E0"></stop>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <h1 className='text-[24px] font-semibold text-[#ffffffe6] leading-none d-flex items-center'>
                            <img src={logo} alt="Logo" className="h-[28px] mr-[6px] hidden lg:block" /> Meta Tags Kit
                        </h1>
                    </div>
                    <p className='text-[14px] leading-[22px] text-[#a7a7a780] mt-[20px]'>
                        AIKIT.AI's Meta Tag Kit is an SEO-focused AI tool designed to enhance your website's search engine visibility. Ideal for digital marketers and content creators, it allows for the efficient creation of meta tags tailored to your specific online content.This tool simplifies the SEO process, ensuring your website or blog ranks higher and reaches a wider audience.
                    </p>
                </div>
                <div className='w-100 mb-[16px]' >
                    <div className="mb-[32px]">
                        <div>
                            <label 
                                className='text-[#A7A7A7] text-[12px] leading-[14px] block mb-[10px]'
                                htmlFor='add-on'
                            >
                                Elements
                            </label>
                            <div className='flex gap-[10px]'>
                                <input
                                    id='add-on'
                                    className='h-[40px] p-[8px] px-[14px] flex-1 bg-[#1B1B1B] rounded-[8px]
                                    border border-solid border-[#333] text-[14px]'
                                    placeholder='Input custom element...'
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                />
                                <button 
                                    className='h-[40px] flex items-center justify-center gap-[8px] px-[12px]
                                    radial-bg rounded-full text-[#331A04] text-[14px] font-medium'
                                    disabled={!inputValue}
                                    onClick={() => {
                                        setGenerateForm((prevState) => ({
                                            ...prevState,
                                            elements: [...elements, inputValue]
                                        }))
                                        setInputValue('');
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M10 4.16699V15.8337" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.16602 10H15.8327" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    Add
                                </button>
                            </div>
                        </div>
                        <ul className='flex gap-[8px] flex-wrap mt-[16px]'>
                            {elements?.map((option, index) => <li
                                key={ index }
                                className={classNames(`text-[#ffffff80] flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                                border border-solid bg-[#202020] rounded-[24px] border-[#383838]`)}
                            >
                                {option}
                                <CloseIcon className='cursor-pointer' onClick={() => {
                                    setGenerateForm((prevState) => ({
                                        ...prevState,
                                        elements: elements.filter(item => item !== option)
                                    }))
                                }}/>
                            </li>)}
                        </ul>
                        <div className='flex items-center gap-[16px] my-[16px]'>
                            <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
                            <span className='text-[12px] text-[#A7A7A7]'>or select</span>
                            <div className='h-[1px] flex-1 bg-[#1F1F1F]'/>
                        </div>
                        <ul className='flex gap-[8px] flex-wrap'>
                            { tagsList?.map((option, index) => <li
                                key={ index }
                                className={classNames(`flex h-[26px] pl-[10px] pr-[6px] items-center gap-[6px] text-[12px]
                                border border-solid bg-[#202020] rounded-[24px] cursor-pointer`,
                                elements?.includes(option) ? 'border-orange-primary text-orange-primary' : 'border-[#383838] text-[#ffffff80]')}
                                onClick={() => {
                                    if(elements.includes(option)) {
                                        setGenerateForm((prevState) => ({
                                            ...prevState,
                                            elements: prevState.elements.filter(item => item !== option)
                                        }));
                                    } else {
                                        setGenerateForm((prevState) => ({
                                            ...prevState,
                                            elements: [...prevState.elements, option]
                                        }));
                                    }
                                }}
                            >
                                { option }
                                { elements?.includes(option) ? <CheckIcon /> : <PlusIcon /> }
                            </li>)}
                        </ul>
                    </div>

                    <div className='mb-[16px]'>
                        <div className='flex justify-between items-center'>
                            <label className='text-[12px] leading-[14px] text-[#A7A7A7] block'>
                                Description
                            </label>
                            <NavLink to={`/kit-details/meta-tags`} className="hover:opacity-70">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M7.00019 7.41542L7.7746 6.98518C8.16108 6.7705 8.40078 6.36316 8.40078 5.92106C8.355 5.18588 7.7245 4.62543 6.98902 4.66615C6.33184 4.63887 5.74827 5.08311 5.59961 5.72383" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                    <circle cx="7.00023" cy="7.00023" r="5.25219" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.05913 9.27624C7.05909 9.30845 7.03296 9.33454 7.00075 9.33452C6.96854 9.33451 6.94243 9.3084 6.94241 9.27619C6.9424 9.24398 6.96849 9.21785 7.0007 9.21781C7.0162 9.21779 7.03107 9.22394 7.04204 9.2349C7.053 9.24586 7.05915 9.26074 7.05913 9.27624" stroke="#FF8114" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </NavLink>
                        </div>
                        <div className="d-flex relative">
                            <textarea
                                value={description}
                                onChange={(e) => setGenerateForm((prevState) => ({
                                    ...prevState,
                                    description: e.target.value
                                }))}
                                className='bg-[#1B1B1B] w-full resize-none pt-[16px] pb-[8px] px-[14px] rounded-[8px] mt-[10px]
                                border border-solid border-[#333] text-[14px] leading-[22px] text-[#ffffffe6]
                                overflow-y-auto min-h-[220px]'
                                placeholder={`• Create page title for eco-friendly skincare line.\n• Write meta description for a beginner's yoga guide.\n• Alt tags for handcrafted 10 level wood bracelet display.\n• Header suggestions for a gourmet vegan recipe blog.`}
                            />
                        </div>
                    </div>

                    <div className="mb-[16px]">
                        <KeywordsGenerator
                            onChange={(val) => setGenerateForm((prevState) => ({...prevState, keywords: val}))} 
                            data={keywords?.length ? keywords : []}
                        />
                    </div>
                    
                    <div className='generator-settings-part mb-[16px] flex-col lg:flex-row gap-[16px] lg:gap-[0px]'>
                        <div className={`input-custom w-100 mr-[16px]`}>
                            <label className="input-custom__label mb-[10px]">Language</label>
                            <div className="input-custom__wrap-input gen4-settings-select">
                                <SelectBox
                                    className='default-select flex-1'
                                    propsOptions={[
                                        { label: 'Select Language', value: '', placeholder: true },
                                        ...languageOptions.map(item => ({ label: item.title, value: item.title }))
                                    ]}
                                    value={language || ''}
                                    change={(val) => setGenerateForm((prevState) => ({
                                        ...prevState,
                                        language: val
                                    }))}
                                />
                            </div>
                        </div>
                        <div className={`input-custom w-100`}>
                            <label className="input-custom__label mb-[10px]"># of variations</label>
                            <div className="input-custom__wrap-input gen4-settings-select">
                                <NumberInput
                                    key={`variants-${variants}`}
                                    value={variants || 4}
                                    onChange={(val) => setGenerateForm((prevState) => ({
                                        ...prevState,
                                        variants: val
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="generator-settings-part">
                        <div className='input-custom w-50-m-8px'>
                            <label className="input-custom__label mb-[10px]">Brand Tone</label>
                            <SelectBox
                                className='default-select flex-1'
                                propsOptions={brandToneOptions}
                                // value={formValues.brand_tone}
                                // change={(brand_tone) => setFormValues({
                                //     ...formValues,
                                //     brand_tone
                                // })}
                                // value={bot_brand_tone || []}
                                // change={(val) => setGenerateForm(prev => ({...prev, bot_brand_tone: val}))}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="gen4-footer">
            { currentCredits && priceCredits ? 
                <div className='credit-price-block'>
                    <div className="flex items-center gap-[10px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M12.1055 18.4167C11.7305 18.7917 11.2756 18.9792 10.7409 18.9792C10.2062 18.9792 9.7513 18.7917 9.3763 18.4167L1.58464 10.625C1.37398 10.4179 1.23105 10.1987 1.15582 9.96735C1.08059 9.73603 1.04297 9.49693 1.04297 9.25004V2.93754C1.04297 2.40421 1.22561 1.95143 1.59089 1.57921C1.95616 1.20698 2.40547 1.02087 2.9388 1.02087H9.2513C9.51208 1.02087 9.7616 1.06355 9.99986 1.14892C10.2381 1.23428 10.4608 1.3791 10.668 1.58337L18.418 9.31254C18.8069 9.70143 18.9978 10.1702 18.9909 10.7188C18.9839 11.2674 18.793 11.7292 18.418 12.1042L12.1055 18.4167ZM5.11528 6.14587C5.39959 6.14587 5.64611 6.04008 5.85486 5.82848C6.0636 5.61687 6.16797 5.36687 6.16797 5.07848C6.16797 4.80396 6.0636 4.56254 5.85486 4.35421C5.64611 4.14587 5.39959 4.04171 5.11528 4.04171C4.83096 4.04171 4.58464 4.14751 4.3763 4.3591C4.16797 4.57071 4.0638 4.81377 4.0638 5.08827C4.0638 5.37667 4.16797 5.62504 4.3763 5.83337C4.58464 6.04171 4.83096 6.14587 5.11528 6.14587Z" fill="#EA8207"></path></svg>
                        <p className="text-[14px] text-[#EA8207] leading-normal">{ currentCredits }<span className="opacity-50 ml-[4px]">available credits</span></p>
                    </div>
                    <p className="text-[14px] text-[#EA8207] leading-normal ml-[30px]">{ priceCredits }<span className="opacity-50 ml-[4px]">price in credits</span></p>
                </div>
            : null }
            <div className="d-flex items-center gen4-footer-btns-wrpr">
                <button
                    className="gen4-btn hover:opacity-70"
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                >
                    <span>Generate</span>
                    <IconStars />
                </button>
            </div>
        </div>
    </>;
};

export default MetaTagConfigComponent;