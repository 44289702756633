import React, { useEffect, useState } from 'react';
import SelectBox from "../../components/base/SelectBox";
import NumberInput from "../../components/base/NumberInput";
import { useSelector, useDispatch } from 'react-redux';
import { getFormOptions, getToken } from '../../redux/slices/applicationSlice';
import InputMultiSelect from '../../components/base/InputMultiSelect';
import api from '../../api';

const SettingsGenerator = ({isCallFromGenerate, onChange, data}) => {
    const token = useSelector(getToken);
    const [allOptions, setAllOptions] = useState(data || null);
    const [collections, setCollections] = useState([]);
    console.log(collections);

    const getFormOptionsToneData = useSelector(getFormOptions).filter(({key}) => key === 'tone');
    const getFormOptionsTargetAudienceData = useSelector(getFormOptions).filter(({key}) => key === 'target_audience');
    const getFormOptionsLanguageData = useSelector(getFormOptions).filter(({key}) => key === 'language');
    const brandToneOptions = useSelector(getFormOptions).filter(({key}) => key === 'bot_brand_tone').map(item => ({ value: item.title, label: item.title }));

    useEffect(() => {
        if (!allOptions) return
        onChange && onChange(allOptions)
    }, [allOptions])

    useEffect(() => {
        if(!token) return;
        api.collections.getCollections({token})
        .then(response => {
            setCollections(response);
            const selectedOption = response.find(item => item.isActive)?.id;
            selectedOption && setAllOptions(prev => ({...prev, collectionId: selectedOption}))
        }).catch(e => {
            console.log(e)
        })
    }, [token])
    return (
        <div className={`${isCallFromGenerate && 'call-from-generate'}`}>
        
            <div className='mb-[16px]'>
                <label className="input-custom__label mb-[10px]">Target Audience</label>
                <InputMultiSelect
                    className="min-w-[150px] mr-[8px]"
                    placeholder="Select Target Audience"
                    options={[
                        // {label: 'Select Tone', value: '', placeholder: true},
                        ...getFormOptionsTargetAudienceData.map(item => ({ label: item.title, value: item.title }))
                    ]}
                    value={allOptions?.target_audience || []}
                    change={(val) => setAllOptions(prev => ({...prev, target_audience: val}))}
                />
            </div>
            <div className='mb-[16px]'>
                <label className="input-custom__label mb-[10px]">Tone</label>
                <InputMultiSelect
                    className="min-w-[150px] mr-[8px]"
                    placeholder="Select Tone..."
                    options={[
                        // {label: 'Select Tone', value: '', placeholder: true},
                        ...getFormOptionsToneData.map(item => ({ label: item.title, value: item.title }))
                    ]}
                    value={allOptions?.tone || []}
                    change={(val) => setAllOptions(prev => ({...prev, tone: val}))}
                />
            </div>
            <div className='generator-settings-part flex-col lg:flex-row gap-[16px] lg:gap-[0px]'>
                <div className={`input-custom w-100 mr-[16px]`}>
                    <label className="input-custom__label mb-[10px]">Number of Variations</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <NumberInput value={allOptions?.number_of_blog || 1} onChange={(val) => setAllOptions(prev => ({...prev, number_of_blog: val}))} />
                    </div>
                </div>
                <div className={`input-custom w-100`}>
                    <label className="input-custom__label mb-[10px]">Language</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <SelectBox
                            className='default-select flex-1'
                            propsOptions={[
                                {label: 'Select Language', value: '', placeholder: true},
                                ...getFormOptionsLanguageData.map(item => ({ label: item.title, value: item.title }))
                            ]}
                            value={allOptions?.language || ''}
                            change={(val) => setAllOptions(prev => ({...prev, language: val}))}
                        />
                    </div>
                </div>
            </div>
            <div className='generator-settings-part mt-[16px] flex-wrap gap-[16px] flex-col lg:flex-row'>
                <div className={`input-custom ${isCallFromGenerate ? 'w-full' : 'w-50-m-8px'}`}>
                    <label className="input-custom__label mb-[10px]">Number of Words</label>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <NumberInput value={allOptions?.minAmountWords} onChange={(val) => setAllOptions(prev => ({...prev, minAmountWords: val}))} />
                    </div>
                </div>
                { collections?.length ? 
                    <div className={`input-custom ${isCallFromGenerate ? 'w-full' : 'w-50-m-8px'}`}>
                        <label className="input-custom__label mb-[10px]">Add to Collection</label>
                        <div className="input-custom__wrap-input gen4-settings-select">
                            <SelectBox
                                className='default-select flex-1'
                                propsOptions={[
                                    {label: 'Select Collection', value: '', placeholder: true},
                                    ...collections.map(item => ({ label: item.name, value: item.id }))
                                ]}
                                value={allOptions?.collectionId || ''}
                                change={(val) => setAllOptions(prev => ({...prev, collectionId: val}))}
                            />
                        </div>
                    </div>
                : null }
                <div className={`input-custom ${isCallFromGenerate ? 'w-full' : 'w-50-m-8px'}`}>
                    <label className="input-custom__label mb-[10px]">Brand Tone</label>
                    <SelectBox
                        className='default-select flex-1'
                        propsOptions={brandToneOptions}
                        
                        value={allOptions?.bot_brand_tone || []}
                        change={(val) => setAllOptions(prev => ({...prev, bot_brand_tone: val}))}
                    />
                </div>
            </div>
            

            {/* <div className='generator-settings-part mt-[16px]'>
                <div className={`input-custom w-100`}>
                    <div className="d-flex items-center justify-between">
                        <label className="input-custom__label mb-[10px]">Profile Voice</label>
                        <button className="d-flex items-center mb-[10px] text-[12px] text-orange-primary">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-[6px]">
                                <path d="M7 2.91699V11.0837" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M2.91602 7H11.0827" stroke="#FF8114" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Add Profile Voice
                        </button>
                    </div>
                    <div className="input-custom__wrap-input gen4-settings-select">
                        <SelectBox
                            className='default-select flex-1'
                            propsOptions={[
                                {label: 'Select profile voice', value: '', placeholder: true},
                                {label: 'Neutral', value: 'Neutral'},
                            ]}
                            // value={allOptions?.tone || ''}
                            // change={(val) => setAllOptions(prev => ({...prev, tone: val}))}
                        />
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default SettingsGenerator;