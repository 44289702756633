const usersBase = 'api/users';

export const users = (instance) => ({
    async getUser(payload) {
        const { data } = await instance.get(`${usersBase}/me`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async updateUser(payload) {
        const { data } = await instance.putForm(`${usersBase}/me`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async updateUserSettings(payload) {
        const { data } = await instance.put(`${usersBase}/me/settings`, payload.data, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        });
        return data;
    },
    async clickReferralCode(payload) {
        const { data } = await instance.put(`${usersBase}/increment-click/${payload}`);
        return data;
    },
})